export const selectedMaintenanceTab = {
  "corrective-maintenance": "Corrective Maintenance Tickets",
  "preventive-maintenance": "Preventative Maintenance Tickets",
  "remedial-maintenance": "Remedial Maintenance Tickets",
  "string-maintenance": "String Lists",
};

export const CATEGORY = {
  CORRECTIVE: "corrective",
  REMEDIAL: "remedial",
  PREVENTATIVE: "preventative",
  PREVENTATIVE_MASTER: "preventative_master",
  PREVENTATIVE_SCHEDULE: "preventative_schedule",
};

export const taskCategory = (categoryType)=>{
  const categories = {
    corrective:"Corrective",
    remedial:"Remedial",
    preventative :"Preventative"
  }
  return categories[categoryType] || "-";
}

export const ticketViewType = (ticketType)=>{
  const tickets = {
    corrective:"CLIENT_CORRECTIVE_MAINTENANCE",
    remedial:"CLIENT_REMEDIAL_MAINTENANCE",
    preventative :"CLIENT_PREVENTATIVE_MAINTENANCE"
  }
  return tickets[ticketType];
}

export const MONTH_OPTIONS = [
  { label: 'January', value: 0 },
  { label: 'February', value: 1 },
  { label: 'March', value: 2 },
  { label: 'April', value: 3 },
  { label: 'May', value: 4 },
  { label: 'June', value: 5 },
  { label: 'July', value: 6 },
  { label: 'August', value: 7 },
  { label: 'September', value: 8 },
  { label: 'October', value: 9 },
  { label: 'November', value: 10 },
  { label: 'December', value: 11 }]